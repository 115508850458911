import React, { Component } from 'react';
import { withRouter,Link } from 'react-router-dom';
import logo from '../../assets/img/android.png';

class SignEnd extends Component {
	componentWillMount() {
		document.getElementById('body').className = 'bg-gradient-primary'
		console.log(window.location.href);
		console.log(window.location.pathname);
	}
	render() {
		return (
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-6 col-md-9">
						<div className="logo-login">
							<img src={logo}></img>
						</div>
						<div className="card o-hidden border-0 shadow-lg card-login">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-12">
										<div className="p-5">
											<h1 className="title-login h4 text-gray-900 mb-4 text-center font-weight-bold">REUNIÃO DE REPRESENTANTES DO SINPEEM</h1>
											<hr />
											<div className="text-center">
												<h2 className="h4 text-gray-900 mb-4">Reunião Encerrada. Até breve!</h2>
											</div>
											<Link to="/" className="btn btn-primary btn-user btn-block">Retornar a página de login</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		)
	}
}

export default withRouter(SignEnd);
