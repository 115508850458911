import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

 //const firebaseConfig = {
   //  apiKey: "AIzaSyC0RI1dag6LQrIzsHF0l8GSMH321G3CEU4",
   //  authDomain: "assembleia-st.firebaseapp.com",
   //  databaseURL: "https://assembleia-st-default-rtdb.firebaseio.com",
  //   projectId: "assembleia-st",
   //  storageBucket: "assembleia-st.appspot.com",
  //   messagingSenderId: "202400716766",
  //   appId: "1:202400716766:web:e4b35a80d124cabcdaef4f",
   //  measurementId: "G-5KN7PH4FY0"
 //  };
  const firebaseConfig = {
    // apiKey: "AIzaSyCGx2i1qB5zHXDsBKrKse8dd5W8_XE3ryc",
    // authDomain: "sinpeem-representantes.firebaseapp.com",
    // databaseURL: "https://sinpeem-representantes-default-rtdb.firebaseio.com",
    // projectId: "sinpeem-representantes",
    // storageBucket: "sinpeem-representantes.appspot.com",
    // messagingSenderId: "331930344919",
    // appId: "1:331930344919:web:ce897b9e9abab0d2cebf92",
    // measurementId: "G-ZXDWQQK7TN"
    apiKey: "AIzaSyCAG5Md8per65L1vxu9SuazxO99Tm5F-YM",
    authDomain: "sinpeemreunioes.firebaseapp.com",
    databaseURL: "https://sinpeemreunioes-default-rtdb.firebaseio.com",
    projectId: "sinpeemreunioes",
    storageBucket: "sinpeemreunioes.appspot.com",
    messagingSenderId: "320549519526",
    appId: "1:320549519526:web:fad63f978cef6b3fb43467",
    measurementId: "G-DD621PNY8D"
  };


const app = initializeApp(firebaseConfig);
getDatabase(app);

export default app
