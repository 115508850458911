import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import User from '../../services/user';
import { unAuthenticatedAxiosInstance as axios } from './../../services/axios'
import Swal from 'sweetalert2'
import logo from '../../assets/img/android.png';
class SignIn extends Component {

	constructor(props) {
		super(props);

		this.state = {
			cpf: "",
			rf: ""
		}
	}

	componentWillMount() {
		document.getElementById('body').className = 'bg-gradient-primary'
		console.log(window.location.href);
		axios.get('lives').then(response => {
			if(response.data.body == "[]" && window.location.pathname != "/secret/login"){
				let redirectRoute = "/eventos-encerrados"
				window.location = (redirectRoute)
			}
		})
	}

	handleLogin = () => {
		this.state.rf = this.state.rf.substring(0,7).replaceAll(".","").replaceAll("-","")
		this.state.cpf = this.state.cpf.replaceAll(".","").replaceAll("-","")

		var {cpf,rf} = { ...this.state }
		console.log(cpf, "cpf")
		console.log(rf, "rf")
		axios.post("login", { ...this.state }).then(response => {
			console.log("login response is", response);
			localStorage.setItem('authenticationToken', response.data.token)

			let redirectRoute = "/watch"

			if(User.resolveToken(response.data.token).tipo === 2) {
				redirectRoute = "/dashboard"
			}

			window.location = (redirectRoute)
			
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Erro não localizamos o usuário. Verifique se os campos foram preenchidos apenas com números.'
			})
		});
	}

	render() {
		const { rf, cpf } = this.state;
		const style = {
			display:"flex",
			"justify-content": "space-around"
		}
		return (
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-6 col-md-9">
						<div className="logo-login">
							<img src={logo}></img>
						</div>
						<div className="card o-hidden border-0 shadow-lg card-login">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-12">
										<div className="p-5">
											<h1 className="title-login h4 text-gray-900 mb-4 text-center font-weight-bold">REUNIÃO DE REPRESENTANTES DO SINPEEM</h1>
											<hr />
											<div className="text-center">
												<h2 className="h4 text-gray-900 mb-4">Bem-vindo(a)</h2>
											</div>
											<form onSubmit={this.handleSignIn} className="user">
												<div className="form-group">
													<input
														type="number"
														className="form-control form-control-user"
														id="inputRF"
														placeholder="RF (7 dígitos)"
														value={rf}
														onChange={(e) => this.setState({ rf: e.target.value })}
													/>
												</div>
												<div className="form-group">
													<input
														type="text"
														className="form-control form-control-user"
														id="inputCPF"
														placeholder="CPF - Apenas números "
														value={cpf}
														onChange={(e) => this.setState({ cpf: e.target.value })}
														onKeyPress={(e) => e.key === 'Enter' && this.handleLogin()}
													/>
												</div>
												<button onClick={() => this.handleLogin()} type="button" className="btn btn-primary btn-user btn-block">
													Entrar
                            					</button>
												<hr />
											</form>
											<div className="text-center">
												<p>Caso tenha problemas para acessar entrar em contato pelo telefone 11 33294500</p>
											</div>
									
											{/* <div className="text-center">
												<Link className="small" to="/signup">Create an Account!</Link>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(SignIn);
