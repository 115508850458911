import React, { Component } from 'react';
import convertUTCDateTimeToBrazilianDateTime from '../../services/converter'
import { CSVLink } from "react-csv";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
class PresenceListComponent extends Component {
    renderPresenceRow() {        
        var buffer = []

        this.props.presences.map((p, i) => {
            buffer.push(
                <tr key={i}>
                    <td>{p.name}</td>
                    <td>{convertUTCDateTimeToBrazilianDateTime(p.created_at)}</td>
                </tr>
            )
        })

        return buffer
    }

    exportToCSV = (presences, fileName) => {
        var csvData = this.removerDateAt(presences)
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    removerDateAt(data){
        const dataR = data
        dataR.map((p, i) => {
            delete dataR[i].created_at
      }) 
      return dataR;  
    }
    render() {
        return (
            <div>
                <div>
                    {this.props.presences.length} presentes
                    <CSVLink className='btn-download btn btn-primary m-4' data={this.props.presences}>
                         Download CSV
                    </CSVLink>
                    <button className='btn-download btn btn-primary mb-4 mt-4' onClick={(e) => this.exportToCSV(this.props.presences,"reuniao")}>Download Excel</button>

                </div>
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Data e Hora</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Nome</th>
                                <th>Data e Hora</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            { this.renderPresenceRow() }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PresenceListComponent